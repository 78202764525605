import React from "react";
const Frontend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Sale and Marketing</h3>
      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">GMO-Z.com ACE Co., Ltd.</h3>
              <span className="skills__level">
                Presently, I represent the position of Sales & Marketing
                Executive at " Z.com Myanmar: Internet Infrastructure Services
                Provider," a subsidiary of the GMO Internet Group with a global
                presence. My role entails overseeing B2B sales operations,
                including the sale of domains, email services, website
                development services, and cloud services. I'm responsible for
                Sales documentation (Before & After payment) and client
                complaints by methodically investigating issues and finding
                effective solutions. Additionally, I am actively involved in
                content marketing and social media operations for Facebook and
                YouTube.
              </span>
            </div>
          </div>

          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Young Creator</h3>
              <span className="skills__level">
                As a seasoned marketing consultant at Young Creator, I've honed
                my skills in crafting innovative and impactful marketing
                strategies for music videos and commercial video productions. My
                role involves dynamic collaboration with creative teams,
                strategic partnership building, and the development of overall
                shooting operations. My passion for the arts, coupled with
                adaptability and quick decision-making, has been instrumental in
                contributing to the success of projects. As a results-driven
                professional, I thrive in this fast-paced environment, where
                every challenge is met with enthusiasm and a can-do spirit.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frontend;
