import React from "react";

const Backend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Production and Operation</h3>
      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">
                Huawei Technologies Yangon Co., Ltd.
              </h3>
              <span className="skills__level">
                During my previous position in Huawei Technologies Co. Ltd, I
                also manage the over the top applications like Netflix, Mahar
                and Cookie. The name of application is WOWPLAY. I was
                responsible for making a Digital Productive Workflow between two
                telecommunication companies, many suppliers and consumers. Our
                team also made Daily, Monthly production reports (DPR, MPR) and
                managed many Files Transfer Protocol (FTP), Analytical
                Production Systems to process our daily activities in
                applications. Consumers can watch a lot of international movies
                in it. My team was responsible for solving customers’ complaints
                and refund processes with well-known public telecommunication
                company in our country.
              </span>
            </div>
          </div>

          <div className="skills__data">
            <i class="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">The NEXT</h3>
              <span className="skills__level">
                As an executive at The NEXT, I lead projects for music videos,
                commercials and events. I make sure the creative teams work well
                together and that our ideas match our goals. From start to
                finish, I oversee the whole production process. Building good
                relationships with clients is also a big part of what I do. I
                help the business grow by finding new opportunities and making
                deals. My job is all about making sure our videos connect with
                people and keeping up with what's new in video production and
                marketing. Working at The NEXT, I bring a mix of leadership,
                smart choices, and a love for pushing creative limits.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backend;
